<template>
  <div class="student-verification">
    <h1>{{ $t('verification.student-card') }}</h1>
    <h2 class="subtitle">
      {{ $t('verification.make-sure-the-year-is-clearly-visible') }}
    </h2>
    <DropImage v-model="student_verification_images" :isStudent="true" />
    <SelectField
      v-model="student_id_year"
      :label="$t('verification.student-card-year')"
      :items="years"
    />
    <v-btn
      :disabled="!formIsValid || $store.state.verification.loading"
      v-on:click="next"
      class="button next-button"
      >{{ $t('verification.next-step') }}</v-btn
    >
  </div>
</template>

<script>
import { mapFields } from '@/helpers/store'
import { DropImage, SelectField } from '@/components'
export default {
  name: 'step-3',
  components: { DropImage, SelectField },
  data() {
    return {
      years: []
    }
  },
  methods: {
    async next() {
      const res = await this.$store.dispatch('proceedVerification')
      if (res) this.$router.push('all-set')
    }
  },
  mounted() {
    const { verification } = this.$store.state
    if (
      !verification.name ||
      !verification.street ||
      !verification.number ||
      !verification.city ||
      !verification.country ||
      !verification.postcode ||
      verification.verification_images.length !== 3 ||
      !verification.id_expiry_date
    ) {
      return this.$router.push('step-1')
    }
    let currentYear = new Date().getFullYear() + 1
    const startYear = 2000
    while (currentYear >= startYear) {
      this.years.push(`${currentYear - 1}-${currentYear}`)
      currentYear--
    }
  },
  computed: {
    formIsValid() {
      return this.student_verification_images.length === 2 && this.student_id_year
    },
    ...mapFields({
      fields: ['student_verification_images', 'student_id_year'],
      base: 'verification',
      mutations: 'update_verification_form'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.student-verification {
  .drop-image {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .next-button {
    margin-top: 8px;
  }
}
</style>
