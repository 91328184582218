import Vue from 'vue'
import Vuex from 'vuex'
import verification from './modules/verification'
import session from './modules/session'
import customer from './modules/customer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    verification,
    session,
    customer
  }
})
