const session = {
  state: () => ({
    token: localStorage.getItem('token') || '',
    user: {},
    status: '',
    errors: []
  }),
  mutations: {
    auth_request(state) {
      state.status = 'loading'
      state.errors = []
    },
    auth_success(state, { token, user }) {
      state.status = 'success'
      state.token = token
      state.user = user
      state.errors = []
    },
    auth_error(state, reasons) {
      state.status = 'error'
      state.errors = reasons
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.errors = []
    },
    set_user(state, data) {
      state.user = data
    }
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        resolve()
      })
    },
    login({ commit }, { email, password }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('auth_request')
        this.$http({
          url: '/auth/login',
          data: {
            email,
            password
          },
          method: 'POST'
        })
          .then(resp => {
            const { token, email, sub } = resp.data.data

            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify({ email, id: sub }))
            commit('auth_success', { token, user: { email, id: sub } })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error', err.data.message)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            reject(err)
          })
      })
    },
    getLoggedUser({ commit }) {
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
      if (user) {
        commit('set_user', user)
      }
      return user
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    loggedUser: state => state.user
  }
}
export default session
