<template>
  <div class="id-verification">
    <h1>{{ $t('verification.verify-your-id') }}</h1>
    <h2 class="subtitle">
      {{
        $t(
          'verification.everything-is-watermarked-and-stored-in-an-encrypted-way'
        )
      }}
    </h2>
    <img src="@/assets/images/banner-2.jpg" class="banner-image" />
    <DropImage v-model="verification_images" :isStudent="false" />
    <DatePicker
      :label="$t('verification.id-expiry-date')"
      v-model="id_expiry_date"
      :min="new Date().toISOString()"
    />
    <v-btn
      :disabled="!formIsValid || $store.state.verification.loading"
      @click="next"
      class="button"
      >{{ $t('verification.next-step') }}</v-btn
    >
  </div>
</template>

<script>
import { mapFields } from '@/helpers/store'
import { DropImage, DatePicker } from '@/components'
export default {
  name: 'step-2',
  components: { DropImage, DatePicker },
  methods: {
    async next() {
      if (this.is_student) {
        this.$router.push('step-3')
      } else {
        const res = await this.$store.dispatch('proceedVerification')
        if (res) this.$router.push('all-set')
      }
    }
  },
  mounted() {
    const { verification } = this.$store.state
    if (
      !verification.name ||
      !verification.street ||
      !verification.number ||
      !verification.city ||
      !verification.country ||
      !verification.postcode
    ) {
      this.$router.push('step-1')
    }
  },
  computed: {
    formIsValid() {
      return this.verification_images.length === 3 && this.id_expiry_date
    },
    ...mapFields({
      fields: ['verification_images', 'id_expiry_date', 'is_student'],
      base: 'verification',
      mutations: 'update_verification_form'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.id-verification {
  .banner-image {
    margin-top: 24px;
    border-radius: 8px;
  }
  .drop-image {
    margin: 24px 0px;
  }
}
</style>
