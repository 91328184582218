<template>
  <div>
    <v-data-table
      :options.sync="localOptions"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage ? itemsPerPage : 10"
      :server-items-length="serverItemsLength"
      class="ostron-table"
      @click:row="handleClick"
    >
      <template v-slot:header.status="{ header }">
        <div class="status-sort">
          <v-menu offset-y class="status-sort-menu" dense>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="menu-button"
                >{{ header.text }}
                <v-icon size="16" v-if="!header.selected"
                  >mdi-cog-outline</v-icon
                >
                <v-icon size="16" v-else class="cog-icon-yellow"
                  >mdi-cog</v-icon
                >
              </span>
            </template>
            <v-list dense class="status-sort-list">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in header.options"
                  :key="i"
                  @click="handleOnStatusFilter(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="status-sort-list-text"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-if="header.selected == item">
                    <v-icon size="16" class="status-sort-list-icon"
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </template>
      <!-- Define the custom template -->
      <template v-slot:item.email="{ item }">
        <div class="name">{{ item.name }}</div>
        <div>{{ item.email }}</div>
      </template>
      <template v-slot:item.verification_images="{ item }">
        <div class="verification-images">
          <v-img
            v-for="(img, index) in item.verification_images"
            :key="index"
            :src="img"
            class="img"
          />
        </div>
      </template>
      <template v-slot:item.student_verification_images="{ item }">
        <div class="verification-images">
          <v-img
            v-for="(img, index) in item.student_verification_images"
            :key="index"
            :src="img"
            class="img"
          />
        </div>
      </template>
      <template v-slot:item.verified_by="{ item }">
        <div>
          <div class="name">{{ item.verified_by }}</div>
          <div>{{ item.verified_at }}</div>
        </div>
      </template>
      <template v-slot:item.rejected_by="{ item }">
        <div>
          <div class="name">{{ item.rejected_by }}</div>
          <div>{{ item.rejected_at }}</div>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="status">
          <span :class="item.status">{{ item.status }}</span>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="status">
          <v-btn
            color="error"
            icon
            @click.stop="
              dialog_confirm = true
              selectedId = item.id
            "
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog_confirm" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete </v-card-title>
        <v-card-text> Are you sure? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_confirm = false"> No </v-btn>
          <v-btn color="red darken-1" text @click="onDelete"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: [
    'options',
    'headers',
    'items',
    'itemsPerPage',
    'serverItemsLength',
    'handleClick',
    'handleOnStatusFilter',
    'handleOnDelete'
  ],
  data() {
    return {
      open_status_sort: false,
      dialog_confirm: false,
      selectedId: null
    }
  },
  methods: {
    onDelete() {
      if (this.handleOnDelete) {
        this.handleOnDelete(this.selectedId)
        this.selectedId = null
        this.dialog_confirm = false
      }
    }
  },
  computed: {
    localOptions: {
      get() {
        return this.options
      },
      set(newVal) {
        this.$emit('update:options', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';

.ostron-table {
  &.v-data-table.theme--light {
    background: transparent;

    .v-data-table__wrapper {
      table {
        border-collapse: separate;
        border-spacing: 0 7px;

        thead {
          tr {
            th {
              font-family: $fontFamily;
              opacity: 0.8;
              color: $headerSemiBoldColor;
              height: auto;
              border: none !important;
              padding: 0 20px;
              padding-bottom: 10px;
              font-size: 0.875rem;
            }
          }
        }

        tbody {
          tr {
            cursor: pointer;
            background: white;

            &:hover {
              background: $primaryColorLight !important;
            }

            td {
              font-family: $fontFamily;
              font-size: 1rem;
              color: $black;
              height: auto;
              padding: 16px 20px;
              border: none !important;
              vertical-align: middle;
              .name {
                font-weight: bold;
                font-size: 1.3rem;
              }

              &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
              .verification-images {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .img {
                  margin-right: 8px;
                  width: 64px;
                  height: 64px;
                  // somehow its not enough only set width and height to force the size
                  max-width: 64px;
                  max-height: 64px;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }
              .status {
                .Nieuw {
                  background-color: $primaryColor;
                  padding: 8px 16px;
                  border-radius: 4px;
                }
                .Geverifieerd {
                  color: $verifiedColor;
                }
                .Geweigerd {
                  color: $rejectedColor;
                }
                .Alle {
                }
                .Expired {
                  color: $rejectedColor;
                }
              }
            }
          }
        }
      }
    }

    .v-data-footer {
      border: none;
      font-family: $fontFamily;
      &__select {
        .v-select,
        .v-select.primary--text {
          color: rgba(0, 0, 0, 0.4) !important;
          caret-color: rgba(0, 0, 0, 0.4) !important;
          .v-icon {
            color: rgba(0, 0, 0, 0.4) !important;
            caret-color: rgba(0, 0, 0, 0.4) !important;
          }
        }
      }
    }
  }
}
.status-sort {
  .cog-icon-yellow {
    color: $primaryColor;
  }
  &-list {
    min-width: 164px;
    &-text {
      font-family: $fontFamily;
      font-size: 1rem;
    }
    .v-list-item {
      padding: 0px 4px 0px 16px;
      min-height: 35px;
      .status-sort-list-icon {
        background-color: $primaryColor;
        color: $headerSemiBoldColor;
        font-size: 12px;
        border-radius: 4px;
        height: 16px;
        width: 16px;
      }
      &__content {
        display: flex;
        flex-direction: row;
      }
      &__icon {
        height: auto !important;
        margin: 0px !important;
        margin-left: 32px !important;
        align-self: center;
      }
      &::before {
        background-color: transparent;
      }
      &--active {
        background-color: transparent;
        .v-list-item__content {
          color: $headerSemiBoldColor;
        }
      }
      &:hover {
        background-color: $selectFieldHoverColor;
      }
    }
  }
}
</style>
