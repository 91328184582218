import Vue from 'vue'
import VueRouter from 'vue-router'

import ProxyView from '../views/proxy'
import Login from '../views/login'
import Customer from '../views/admin/customer'
import CustomerDetail from '../views/admin/customer/_id.vue'
import Verification from '../views/verification/index.vue'
import VerificationIntro from '../views/verification/intro.vue'
import VerificationStep1 from '../views/verification/step1.vue'
import VerificationStep2 from '../views/verification/step2.vue'
import VerificationStep3 from '../views/verification/step3.vue'
import VerificationAllSet from '../views/verification/all-set.vue'
import NotFound from '../views/404.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hideHeader: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        return next('/admin')
      }
      next()
    }
  },
  {
    path: '/',
    redirect: '/admin/customer'
  },
  {
    path: '/admin',
    component: ProxyView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/admin/customer'
      },
      {
        path: 'customer',
        name: 'customer',
        component: Customer
      },
      {
        path: 'customer/:id',
        component: CustomerDetail,
        name: 'customer-detail'
      }
    ]
  },
  {
    path: '/verification/:id',
    redirect: '/nl/verification/:id/intro',
    meta: {
      hideHeader: true
    }
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
    meta: {
      hideHeader: true
    }
  },
  {
    path: '/:locale/verification/:id',
    redirect: '/nl/verification/:id/intro',
    meta: {
      hideHeader: true
    }
  },
  {
    path: '/:locale/verification/:id',
    name: 'verification',
    component: Verification,
    children: [
      {
        path: 'intro',
        name: 'intro',
        component: VerificationIntro,
        meta: {
          hideHeader: true
        }
      },
      {
        path: 'step-1',
        name: 'step-1',
        component: VerificationStep1,
        meta: {
          hideHeader: true
        }
      },
      {
        path: 'step-2',
        name: 'step-2',
        component: VerificationStep2,
        meta: {
          hideHeader: true
        }
      },
      {
        path: 'step-3',
        name: 'step-3',
        component: VerificationStep3,
        meta: {
          hideHeader: true
        }
      },
      {
        path: 'all-set',
        name: 'all-set',
        component: VerificationAllSet,
        meta: {
          hideHeader: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, _, next) => {
  if (to.path.startsWith('/logout')) {
    store.dispatch('logout')
    next('/login')
    return
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }

    next(`/login?referrer=${to.path}`)
  } else {
    next()
  }
})

export default router
