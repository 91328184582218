<template>
  <div class="not-found">
    <div class="not-found-content">
      <h1>404</h1>
      <h2>Page or data not found</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found'
}
</script>

<style lang="scss">
@import '@/assets/styles/base/variables';
.not-found {
  display: flex;
  height: 100vh;
  justify-content: center;
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $fontHeading;
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
}
</style>
