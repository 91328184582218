<template>
  <div class="client">
    <Loading :loading="loading" :absolute="true" :height="10" />
    <div class="client-detail" v-if="!loading && errors.length === 0">
      <v-alert v-for="err in errors" v-bind:key="err" type="error" text>
        {{ err }}
      </v-alert>
      <div class="client-detail-header">
        <v-btn class="back-button" icon @click="$router.push('/admin/customer')"
          ><v-icon size="24">mdi-arrow-left</v-icon></v-btn
        >
        <h1 class="name">{{ client.name }}</h1>
        <v-btn class="delete" icon color="error" @click="dialog_confirm = true"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </div>
      <v-card class="client-detail-card" elevation="0">
        <div class="client-detail-card-content">
          <div class="client-detail-card-content-column">
            <div class="column-50">
              <div class="id">
                <div class="d-flex flex-direction-row flex-wrap-1 align-center">
                  <h2>ID</h2>
                  <span v-if="isVerified" class="status verified"
                    >Geverifieerd</span
                  >
                  <span v-if="isExpired" class="status expired">Vervallen</span>
                  <span v-if="isRejected" class="status expired">Geweigerd</span>
                </div>
                <div class="id-card-wrapper">
                  <v-img
                    :src="client.images[0]"
                    class="id-card active"
                    @click="viewImage(client.images[0])"
                  />
                  <div class="id-card-wrapper-list">
                    <template v-for="(image, index) in client.images">
                      <v-img
                        @click="viewImage(image)"
                        v-if="index > 0"
                        :src="image"
                        class="id-card small"
                        v-bind:key="index"
                      />
                    </template>
                  </div>
                </div>
              </div>
              <div class="field">
                <h5>ID expiry date</h5>
                <span>{{ parseDate(client.idExpirationDate) }}</span>
              </div>
              <div class="field">
                <h5>Naam</h5>
                <span>{{ client.name }}</span>
              </div>
              <div class="field">
                <h5>Geboortedatum</h5>
                <span>{{ dob }}</span>
              </div>
              <div class="field">
                <h5>Adres</h5>
                <span
                  >{{ client.address.street }} {{ client.address.number }}
                  {{ client.address.additional_number }}
                  {{ client.address.city }}, {{ client.address.country }}</span
                >
              </div>
              <div class="field" v-show="isRejected">
                <h5>Reden Geweigerd</h5>
                <div>{{ client.rejectionReason }}</div>
              </div>
              <div class="field" v-show="isRejected">
                <h5>Ingediend op</h5>
                <div>{{ parseDateTime(client.submittedAt) }}</div>
              </div>
              <div class="field" v-show="client.verifiedBy">
                <h5>Geverifieerd op</h5>
                <div><strong>{{ client.verifiedBy }}</strong> op {{ verifiedAt }}</div>
              </div>
            </div>
            <div class="column-50" v-if="client.isStudent">
              <div class="id">
                <h2>Studentenkaart</h2>
                <div class="id-card-wrapper">
                  <img
                    :src="client.studentImages[0]"
                    @click="viewImage(client.studentImages[0])"
                    class="id-card active"
                  />
                  <div class="id-card-wrapper-list">
                    <template v-for="(image, index) in client.studentImages">
                      <img
                        @click="viewImage(image)"
                        v-if="index > 0"
                        v-bind:key="index"
                        :src="image"
                        class="id-card small"
                      />
                    </template>
                  </div>
                </div>
              </div>
              <div class="field">
                <h5>Studentenkaart jaar</h5>
                <span>{{ client.studentCardYear }}</span>
              </div>
            </div>

            <div class="column">
              <div class="id">
                <h2>Questionnaire</h2>

                <div class="field">
                  <h5>{{ $t('verification.question_1') }}</h5>
                  <span v-html="convertLinks(client.question_1)"/>
                </div>

                <div class="field">
                  <h5>{{ $t('verification.question_2') }}</h5>
                  <span v-html="convertLinks(client.question_2)"/>
                </div>

                <div class="field">
                  <h5>{{ $t('verification.question_3') }}</h5>
                  <span v-html="convertLinks(client.question_3)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="client-detail-card-actions" v-if="verificationNeeded">
          <div>
            <v-btn class="button inverted" @click="reject_dialog = true"
              >Afwijzen</v-btn
            >
          </div>
          <div>
            <v-btn class="button" @click="verify_dialog = true"
              >Verifi�ren</v-btn
            >
          </div>
        </div>
      </v-card>
      <v-dialog v-model="reject_dialog" scrollable max-width="439px">
        <v-card class="reject-card">
          <h2>Type the reason for rejection</h2>
          <TextArea v-model="reject_reason" />
          <TextField v-model="rejected_by"></TextField>
          <v-btn
            :disabled="reject_reason.trim().length === 0 || rejected_by.trim().length === 0"
            class="button inverted"
            @click="onStatusUpdate('REJECTED')"
            >Afwijzen</v-btn
          >
        </v-card>
      </v-dialog>
      <v-dialog v-model="verify_dialog" scrollable max-width="439px">
        <v-card class="reject-card">
          <h2>Verified by</h2>
          <TextField v-model="verified_by"></TextField>
          <v-btn
            :disabled="!verified_by"
            class="button"
            @click="onStatusUpdate('VERIFIED')"
            >Verifi�ren</v-btn
          >
        </v-card>
      </v-dialog>
      <v-dialog
        content-class="image-modal"
        v-model="image_dialog"
        max-width="800px"
      >
        <div class="image-viewer">
          <v-btn icon @click="closeImage" class="image-viewer-close-image"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-img class="image-viewer-full-image" :src="active_image"></v-img>
        </div>
      </v-dialog>
      <v-dialog v-model="dialog_confirm" max-width="290">
        <v-card>
          <v-card-title class="headline"> Delete </v-card-title>
          <v-card-text> Are you sure? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog_confirm = false"> No </v-btn>
            <v-btn color="red darken-1" text @click="onDelete"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ alert_text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="yellow" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { TextArea, Loading, TextField } from '@/components'
import moment from 'moment'

export default {
  name: 'customer-detail',
  components: { TextArea, Loading, TextField },
  data() {
    return {
      dialog_confirm: false,
      snackbar: false,
      alert_text: '',
      errors: [],
      reject_dialog: false,
      reject_reason_list: ['ID not readable', 'Expired ID'],
      reject_reason: '',
      rejected_by: '',
      verify_dialog: false,
      verified_by: '',
      active_image: null,
      loading: true,
      client_images: [],
      student_images: [],
      client: null,
      image_dialog: false
      // Example returns from API
      // client: {
      //   status: 'NEW',
      //   studentImages: [
      //     'https://ostron.s3.amazonaws.com/verification-photos/1a288d6c-7151-439a-a5d4-af6395e5d757.png?AWSAccessKeyId=AKIAXNCYE5EOBIYN475D&Expires=1616662738&Signature=CVabYynYhbriVj82WVWy5DfRK3g%3D'
      //   ],
      //   images: [
      //     'https://ostron.s3.amazonaws.com/verification-photos/a5f03193-cc8e-445d-9b7c-62319945479d.png?AWSAccessKeyId=AKIAXNCYE5EOBIYN475D&Expires=1616662738&Signature=8y6tOggKNNN8YB5EKY0T4nCSs3I%3D',
      //     'https://ostron.s3.amazonaws.com/verification-photos/0a2b5b73-d0de-47fe-9d32-d7a03cbeddbd.png?AWSAccessKeyId=AKIAXNCYE5EOBIYN475D&Expires=1616662738&Signature=wu%2FQHmPl%2Fl7sNTFVCmDGl3suBjI%3D'
      //   ],
      //   studentCardYear: '2019-2020',
      //   isStudent: true,
      //   name: 'Robby test 123',
      //   address: {
      //     street: 'plered raya',
      //     number: '12a',
      //     city: 'Bandung',
      //     country: 'Indonesia',
      //     postcode: 40291
      //   },
      //   idExpirationDate: '2020-03-22T12:34:00.000Z',
      //   rejectionReason: null
      // }
    }
  },
  methods: {
    async onDelete() {
      this.loading = true
      const { data } = await this.$store.dispatch('deleteCustomer', {
        id: this.$route.params.id
      })
      if (data?.code === 200) {
        this.dialog_confirm = false
        this.$router.push({ name: 'customer' })
      } else {
        this.snackbar = true
        this.alert_text = 'Could not delete data, please try again later.'
      }
      this.loading = false
    },
    closeImage() {
      this.image_dialog = false
      this.active_image = null
    },
    viewImage(image) {
      this.image_dialog = true
      this.active_image = image
    },
    parseDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    parseDateTime(date) {
      return moment(date).format('DD.MM.YYYY hh.mm.ss')
    },
    onStatusUpdate(status) {
      this.loading = true
      this.$http({
        url: `/customer/${this.$route.params.id}`,
        method: 'PATCH',
        data: {
          status: status,
          reason: status === 'REJECTED' ? this.reject_reason : null,
          verifiedBy: status === 'VERIFIED' ? this.verified_by : null,
          verifiedAt: status === 'VERIFIED' ? moment.utc().toISOString() : null,
          rejectedBy: status === 'REJECTED' ? this.rejected_by : null
        }
      })
        .then(() => {
          this.reject_dialog = false
          this.reject_reason = ''
          this.rejected_by = ''
          this.verify_dialog = false
          this.verified_by = ''
          this.fetchData()
        })
        .catch((e) => {
          this.loading = false
          console.error(e.response.statusText)
        })
    },
    fetchData() {
      this.$http({
        url: `/customer/${this.$route.params.id}`
      })
        .then((resp) => {
          this.client = resp.data.data.verification
          this.loading = false
        })
        .catch((e) => {
          this.loading = false

          if (e.response.data) {
            this.errors = e.response.data.message
          } else {
            this.errors = [e.response.statusText]
          }
        })
    },
    convertLinks(text) {
      // Convert URLs in text to clickable links
      const urlPattern = /(https?:\/\/[^\s]+)/g

      if (!text || text === '') {
        return '-'
      }

      return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>')
    }
  },
  computed: {
    verificationNeeded() {
      return this.client.status === 'NEW'
    },
    isVerified() {
      return this.client.status === 'VERIFIED'
    },
    isExpired() {
      return this.client.status === 'EXPIRED'
    },
    isRejected() {
      return this.client.status === 'REJECTED'
    },
    verifiedAt() {
      return this.client.verifiedAt
        ? moment(this.client.verifiedAt).format('DD MMM YYYY HH:mm')
        : ''
    },
    dob() {
      return this.client.dob ?? '-'
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';
.client {
  background-color: $borderColor;
  height: 100%;
}
.client-detail {
  padding: 40px;
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .back-button {
      margin-bottom: 26px;
      .v-icon {
        color: $black;
      }
    }
    .name {
      padding: 0px 8px 24px;
    }
    .delete {
      margin-bottom: 22px;
    }
  }
  &-card {
    padding: 24px;
    min-height: calc(100vh - 250px);
    &-content {
      &-column {
        display: flex;
        flex-direction: row;
        font-family: $fontFamily;
        flex-wrap: wrap;

        .column {
          width: 100%;
          margin-right: 6%;
          @media only screen and (max-width:600px) {
            margin-right: 0px;
          }
        }

        .column-50:nth-child(odd) {
          width: 50%;
          margin-right: 6%;
        }
        .column-50:nth-child(even) {
          width: calc(50% - 6%);
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 20px;
          font-family: $fontHeading;
        }
        .id {
          .status {
            margin-left: 8px;
            font-size: 1rem;
            margin-bottom: 20px;
            &.verified {
              color: $verifiedColor;
            }
            &.expired {
              color: $rejectedColor;
            }
          }
          &-card-wrapper {
            display: flex;
            flex-direction: row;
            min-height: 240px;
            margin-bottom: 20px;
            @media only screen and (max-width:600px) {
              flex-direction: column;
              max-height: unset;
            }
            .id-card {
              border-radius: 8px;
              &-wrapper-list {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                @media only screen and (max-width:600px) {
                  flex-direction: row;
                  justify-content: space-around;
                }
              }
              &.small {
                width: 64px;
                min-height: 64px;
                max-width: 64px;
                max-height: 64px;
                cursor: pointer;
                object-fit: cover;
                margin-bottom: 14px;
                margin-right: 14px;
                border: 1px solid white;
                &:hover {
                  border: 1px solid $black;
                }
                @media only screen and (max-width:600px) {
                  margin: 14px 0px;
                  width: auto;
                  height: 64px;
                }
              }
              &.active {
                width: 340px;
                height: auto;
                max-height: 220px;
                margin-right: 16px;
                cursor: pointer;
                object-fit: cover;
                @media only screen and (max-width:600px) {
                  margin-right: 0px;
                }
              }
            }
          }
        }

        .field {
          margin-bottom: 24px;
          h5 {
            font-size: 0.75rem;
            color: $black;
            opacity: 0.4;
          }
          span {
            font-size: 1rem;
            color: $black;
            opacity: 0.8;
          }
        }
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 400px;
      div {
        flex-grow: 1;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

.reject-card {
  padding: 48px;
  h2 {
    font-family: $fontHeading;
    font-size: 1.5rem;
    color: $black;
    font-weight: 600;
    margin-bottom: 12px;
  }
}
.image-modal {
  box-shadow: none !important;
  .image-viewer {
    position: relative;
    &-close-image {
      background-color: $primaryColor;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 100;
    }
    &-full-image {
      object-fit: contain;
    }
  }
}
</style>
