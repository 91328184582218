<template>
  <v-app>
    <Header v-if="!$route.meta.hideHeader" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { Header } from '@/components'

export default {
  name: 'App',
  components: {
    Header
  },
  data: () => ({
    //
  }),
  created: function() {
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err
      })
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/styles.scss';
</style>
