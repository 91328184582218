<template>
  <div class="app-select-field">
    <label class="app-select-field-label">{{ label }}</label>
    <v-select
      v-model="localValue"
      :items="items"
      menu-props="auto"
      hide-details
      label="Select"
      single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    localValue: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';
.app-select-field {
  font-family: $fontFamily;
  &-label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  .v-text-field {
    padding-top: 0px;
    margin-top: 0px !important;
    .v-input__slot {
      &::after,
      &::before {
        display: none;
      }
      .v-select__slot {
        border: 1px solid $borderColor;
        margin-bottom: 24px;
        padding: 4px 14px;
        border-radius: 4px;
        .v-label {
          display: none;
        }
        .v-icon {
          color: $trackColor !important;
        }
      }
    }
  }
}
.v-select-list {
  font-family: $fontFamily;
  padding: 0px !important;
  .v-list-item {
    &:hover {
      color: $black !important;
      background-color: $selectFieldHoverColor !important;
    }
    &--active.primary--text {
      color: $primaryColor !important;
      background-color: $selectFieldHoverColor !important;
      .v-list-item__content {
        color: $black;
      }
    }
  }
}
.v-menu__content {
  scrollbar-color: $borderColor;
  scrollbar-track-color: $trackColor;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.25em;
    &-track {
      background-color: $borderColor;
    }
    &-thumb {
      background-color: $trackColor;
    }
  }
}
</style>
