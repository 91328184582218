<template>
  <v-app-bar app color="'#232324'" dark height="75" class="header">
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Name"
        class="shrink"
        contain
        src="../../assets/images/logo.svg"
        width="90"
      />
    </div>

    <v-spacer></v-spacer>

    <v-btn to="/" text v-if="false">
      <span class="mr-2">Login</span>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          plain
          v-bind="attrs"
          v-on="on"
        >
          {{ email }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item link @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    email() {
      const logged = this.$store.getters.loggedUser
      if (logged.email) {
        return logged.email
      }
      return ''
    }
  },
  methods: {
    logout() {
      this.$router.push('/logout')
    }
  },
  mounted() {
    this.$store.dispatch('getLoggedUser')
  },
  updated() {
    // this.$store.dispatch('getLoggedUser')
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';

.header {
  .v-toolbar__content {
    padding: 4px 30px;
  }
}
</style>
