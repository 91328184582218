export default {
  NEW: 'Nieuw',
  VERIFIED: 'Geverifieerd',
  REJECTED: 'Geweigerd',
  EXPIRED: 'Expired',
  Nieuw: 'NEW',
  Geverifieerd: 'VERIFIED',
  Geweigerd: 'REJECTED',
  Expired: 'EXPIRED'
}
