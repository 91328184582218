<template>
  <v-progress-linear
    :active="loading"
    :indeterminate="loading ? loading : false"
    :absolute="absolute ? absolute : false"
    :fixed="fixed"
    top
    color="yellow lighten-2"
    :height="height ? height : 8"
  ></v-progress-linear>
</template>

<script>
export default {
  name: 'loading',
  props: ['loading', 'fixed', 'absolute', 'height']
}
</script>

<style lang="scss">
</style>
