import moment from 'moment'
import { dataURItoBlob } from '../../helpers/images'

const verification = {
  state: () => ({
    id: '',
    name: '',
    email: '',
    street: '',
    number: '',
    additional_number: '',
    postcode: '',
    city: '',
    country: '',
    is_student: false,
    verification_images: [],
    id_expiry_date: null,
    student_verification_images: [],
    student_id_year: null,
    dob: null,
    question_1: '',
    question_2: '',
    question_3: '',
    errors: [],
    loading: false
  }),
  mutations: {
    update_verification_form(state, object) {
      state[object.field] = object.value
    }
  },
  actions: {
    getUserById({ commit }, { id }) {
      commit('update_verification_form', { field: 'errors', value: [] })
      return new Promise((resolve, reject) => {
        this.$http({
          url: `/customer/unverified/${id}`,
          method: 'GET'
        }).then((resp) => {
          const { data } = resp.data
          commit('update_verification_form', { field: 'name', value: data.crms_meta.name })
          commit('update_verification_form', { field: 'email', value: data.crms_meta.emails.length > 0 ? data.crms_meta.emails[0] : '' })
          if (data?.verification) {
            commit('update_verification_form', { field: 'street', value: data.verification?.address?.street })
            commit('update_verification_form', { field: 'number', value: data.verification?.address?.number })
            commit('update_verification_form', { field: 'city', value: data.verification?.address?.city })
            commit('update_verification_form', { field: 'country', value: data.verification?.address?.country })
            commit('update_verification_form', { field: 'postcode', value: data.verification?.address?.postcode })
            commit('update_verification_form', { field: 'additional_number', value: data.verification?.address?.additional_number })
            commit('update_verification_form', { field: 'is_student', value: data.verification?.isStudent })
            commit('update_verification_form', { field: 'dob', value: data.verification?.dob })
            commit('update_verification_form', { field: 'question_1', value: data.verification?.question_1 })
            commit('update_verification_form', { field: 'question_2', value: data.verification?.question_2 })
            commit('update_verification_form', { field: 'question_3', value: data.verification?.question_3 })
          }
          resolve(true)
        }).catch((err) => {
          if (err !== 401) commit('update_verification_form', { field: 'errors', value: ['Could not get data.'] })
          reject(err)
        })
      })
    },
    proceedVerification({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('update_verification_form', { field: 'errors', value: [] })
        commit('update_verification_form', { field: 'loading', value: true })
        const formData = new FormData()
        // convert base64 to File object
        for (const i in state.verification_images) {
          const image = dataURItoBlob(state.verification_images[i])
          formData.append('images', image)
        }
        if (state.is_student) {
          for (const i in state.student_verification_images) {
            const image = dataURItoBlob(state.student_verification_images[i])
            formData.append('studentImages', image)
          }
          formData.append('studentCardYear', state.student_id_year)
        }
        formData.append('name', state.name)
        formData.append('dob', state.dob)
        formData.append('street', state.street)
        formData.append('street_number', state.number)
        formData.append('city', state.city)
        formData.append('country', state.country)
        formData.append('postcode', state.postcode)
        formData.append('additional_number', state.additional_number)
        formData.append('idExpirationDate', moment.utc(state.id_expiry_date).startOf('day').toISOString())
        formData.append('isStudent', state.is_student)
        formData.append('question_1', state.question_1)
        formData.append('question_2', state.question_2)
        formData.append('question_3', state.question_3)
        this.$http({
          url: `/customer/${state.id}/verification`,
          method: 'POST',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(resp => {
          commit('update_verification_form', { field: 'loading', value: false })
          if (resp.data.code === 201) {
            resolve(true)
          }
          resolve(false)
        }).catch(err => {
          commit('update_verification_form', { field: 'loading', value: false })
          commit('update_verification_form', { field: 'errors', value: ['Opps, Something wrong please try again later or try with smaller images'] })
          reject(err)
        })
      })
    }
  },
  getters: {}
}
export default verification
