<template>
  <div class="all-set">
    <h1>{{ $t('verification.thanks') }}</h1>
    <h2 class="subtitle">
      {{ $t('verification.we-will-contact-you') }}
    </h2>
    <v-btn v-on:click="done" class="button done-button">{{
      $t('verification.done')
    }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'step-3',
  methods: {
    done() {
      window.location.href = 'https://www.ostron.be'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.all-set {
  .subtitle {
    font-size: 1.125rem;
    font-family: $fontFamily;
    margin-bottom: 32px;
  }
  .done-button {
    margin-top: 12px;
  }
}
</style>
