<template>
  <div class="about-you">
    <h1>{{ $t('verification.welcome') }}</h1>

    <div v-if="showSuccessForm">
      <p>{{ $t('verification.other-option-success') }}</p>
    </div>

    <div v-if="!showForm && !showSuccessForm">
      <p class="pt-5">
        {{ $t('verification.intro-1') }}
        <a :href="$t('verification.privacy-policy-url')">
          {{ $t('verification.privacy-policy') }}
        </a>
      </p>

      <p class="pt-2">
        <v-btn v-on:click="next" class="button">
          {{ $t('verification.continue') }}
        </v-btn>
      </p>

      <p>{{ $t('verification.intro-2') }}</p>

      <p class="pt-3">
        <v-btn v-on:click="toggleForm" class="button">
          {{ $t('verification.other-option') }}
        </v-btn>
      </p>
    </div>

    <div v-if="showForm && !showSuccessForm" class="pt-5">
      <CheckBox
        v-model="video_call"
        :label="$t('verification.other-option-1')"
        @input="toggleVideoCall"
      />
      <CheckBox
        class="pt-5"
        v-model="verify_on_site"
        :label="$t('verification.other-option-2')"
        @input="toggleVerifyOnSite"
      />

      <p>
        <v-btn
          v-on:click="submit"
          class="button mt-5"
          :disabled="!isValid || $store.state.verification.loading"
        >
          {{ $t('verification.save-changes') }}
        </v-btn>
      </p>
    </div>
  </div>
</template>

<script>
import { CheckBox } from '@/components'
import { mapFields } from '@/helpers/store'
export default {
  name: 'intro',
  components: { CheckBox },
  data() {
    return {
      showForm: false,
      video_call: false,
      verify_on_site: false,
      showSuccessForm: false
    }
  },
  async mounted() {
    this.$store.commit('update_verification_form', {
      field: 'id',
      value: this.$route.params.id
    })
    this.$store.commit('update_verification_form', {
      field: 'loading',
      value: true
    })
    try {
      await this.$store.dispatch('getUserById', {
        id: this.$route.params.id
      })
      this.$store.commit('update_verification_form', {
        field: 'loading',
        value: false
      })
    } catch (error) {
      this.$store.commit('update_verification_form', {
        field: 'loading',
        value: false
      })
      console.log(error)
    }
  },
  computed: {
    isValid() {
      return this.video_call || this.verify_on_site
    },
    loading() {
      return this.$store.state.verification.loading
    },
    ...mapFields({
      fields: ['email', 'name'],
      base: 'verification',
      mutations: 'update_verification_form'
    })
  },
  methods: {
    next() {
      this.$router.push('step-1')
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    toggleVideoCall(e) {
      this.verify_on_site = false
    },
    toggleVerifyOnSite(e) {
      this.video_call = false
    },
    getValue() {
      if (this.video_call) {
        return 'Ik wens te verifiëren aan de hand van een videocall met Ostron. Deze optie is enkel van toepassing als uw eerste dag van ophaling meer dan 5 werkdagen in de toekomst ligt.'
      }

      return 'Ik wens te verifiëren door persoonlijk naar het hoofdkantoor van Ostron in Gent te komen, ten laatste op de eerste dag van ophaling.'
    },
    submit() {
      this.$http({
        url: '/mail/send-verification-opt-mail',
        method: 'POST',
        data: {
          name: this.name,
          email: this.email,
          verification_value: this.getValue()
        }
      }).then((response) => {
        this.showSuccessForm = true
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.about-you {
  .form {
    padding-top: 20px;
    .resident {
      margin-bottom: 18px;
    }
    .inline {
      &:first-child {
        width: 70%;
      }
      width: 25%;
    }
    .address {
      &:first-child {
        width: 40%;
      }
      width: 25%;
    }
  }
}
</style>
