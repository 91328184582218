<template>
  <div class="admin">
    <div class="admin-left"></div>

    <div class="admin-right">
      <div class="admin-right-content">
        <form @submit.prevent="login">
          <img
            class="login-logo"
            src="../assets/images/logo-black.svg"
            alt="ostron-logo"
          />
          <h1>Welkom bij OSTRON</h1>
          <p class="login-desc">Log in om aan de slag te gaan.</p>
          <v-alert
            v-for="err in errors"
            v-bind:key="err"
            type="error"
            text
          >
            {{ err }}
          </v-alert>

          <div class="login-form">
            <TextField v-model="email" label="Email" :disabled="loading" />
            <TextField
              v-model="password"
              label="Wachtwoord"
              type="password"
              :disabled="loading"
            />
          </div>

          <v-btn type="submit" class="button login-button" :disabled="loading">Login</v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { TextField } from '@/components'
import $store from '../store'

export default {
  name: 'Login',
  components: {
    TextField
  },
  computed: {
    errors() {
      return $store.state.session.errors
    },
    loading() {
      return $store.state.session.status === 'loading'
    }
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login: function() {
      const { email, password } = this

      this.$store
        .dispatch('login', { email, password })
        .then(() => {
          if (this.$route.query.referrer) {
            return this.$router.push(this.$route.query.referrer)
          }

          this.$router.push('/admin/customer')
        })
        .catch(err => console.log(err.response))
    }
  }
}
</script>

<style lang="scss">
.admin {
  display: flex;

  &-left {
    width: 50%;
    height: 100vh;
    background: url('../assets/images/login-image.jpg');
    background-size: cover;

    @media only screen and (max-width: 640px) {
      display: none;
    }
  }

  &-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .login-logo {
      width: 130px;
      margin-bottom: 35px;
    }

    .login-desc {
      margin-bottom: 15px;
    }

    .login-form {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &-right-content {
    widows: 320px;
  }

  .login-main-image {
    width: 100%;
  }
}
</style>
