<template>
  <div class="verification">
    <Loading :loading="loading" :fixed="true" />
    <v-card class="verification-card" elevation="0">
      <div class="verification-card-left" v-if="currentStep > 1">
        <div class="step" v-bind:class="{ active: currentStep >= 2 }">
          <span class="step-number" v-if="currentStep <= 2">1</span>
          <span class="step-number" v-else
            ><v-icon color="black" size="20">mdi-check</v-icon>
          </span>
          <span class="step-label">{{ $t('verification.about-you') }}</span>
        </div>
        <div
          class="vertical-line-dotted"
          v-bind:class="{ active: currentStep > 1 }"
        ></div>
        <div class="step" v-bind:class="{ active: currentStep >= 3 }">
          <span class="step-number" v-if="currentStep <= 2">2</span>
          <span class="step-number" v-else
            ><v-icon color="black" size="20">mdi-check</v-icon>
          </span>
          <span class="step-label">{{
            $t('verification.id-verification')
          }}</span>
        </div>
        <div
          class="vertical-line-dotted"
          v-bind:class="{ active: currentStep > 2 }"
        ></div>
        <div class="step" v-bind:class="{ active: currentStep >= 4 }">
          <span class="step-number" v-if="currentStep <= 3">3</span>
          <span class="step-number" v-else
            ><v-icon color="black" size="20">mdi-check</v-icon>
          </span>
          <span class="step-label">{{ $t('verification.student-card') }}</span>
        </div>
      </div>
      <div class="verification-card-right">
        <div class="options">
          <span class="current-step">
            <span v-if="currentStep > 1">
              {{ $t('verification.step') }} {{ currentStep - 1 }}/3
            </span>
          </span>
          <div>
            <v-btn
              text
              v-bind:class="{ active: $i18n.locale == 'nl' }"
              v-on:click="changeLocale('nl')"
              >NL</v-btn
            >
            <v-btn
              text
              v-bind:class="{ active: $i18n.locale == 'fr' }"
              v-on:click="changeLocale('fr')"
              >FR</v-btn
            >
            <v-btn
              text
              v-bind:class="{ active: $i18n.locale == 'en' }"
              v-on:click="changeLocale('en')"
              >ENG</v-btn
            >
            <v-btn
              text
              v-bind:class="{ active: $i18n.locale == 'de' }"
              v-on:click="changeLocale('de')"
              >DE</v-btn
            >
          </div>
        </div>
        <div class="steps">
          <div class="step" v-bind:class="{ active: currentStep >= 1 }">
            <span class="step-number" v-if="currentStep <= 1">1</span>
            <span class="step-number" v-else
              ><v-icon color="black" size="20">mdi-check</v-icon>
            </span>
          </div>
          <div
            class="horizontal-line-dotted"
            v-bind:class="{ active: currentStep > 1 }"
          ></div>
          <div class="step" v-bind:class="{ active: currentStep >= 2 }">
            <span class="step-number" v-if="currentStep <= 2">2</span>
            <span class="step-number" v-else
              ><v-icon color="black" size="20">mdi-check</v-icon>
            </span>
          </div>
          <div
            class="horizontal-line-dotted"
            v-bind:class="{ active: currentStep > 2 }"
          ></div>
          <div class="step" v-bind:class="{ active: currentStep >= 3 }">
            <span class="step-number" v-if="currentStep <= 3">3</span>
            <span class="step-number" v-else
              ><v-icon color="black" size="20">mdi-check</v-icon>
            </span>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path"/>
        </transition>
      </div>
      <v-snackbar v-model="showError" :multi-line="true">
        <span v-for="(error, index) in errors" :key="index">{{ error }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="showError = []">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { Loading } from '@/components'
export default {
  name: 'Verification',
  components: { Loading },
  computed: {
    currentStep() {
      let step = this.$route.path.split('-')

      if (step[0].includes('intro')) {
        return 1
      }

      step = step[step.length - 1]

      return step < 4 ? parseInt(step) + 1 : 4
    },
    errors() {
      return this.$store.state.verification.errors
    },
    loading() {
      return this.$store.state.verification.loading
    },
    showError: {
      get() {
        return this.$store.state.verification.errors.length > 0
      },
      set(newVal) {
        this.$store.commit('update_verification_form', {
          field: 'errors',
          value: newVal
        })
      }
    }
  },
  methods: {
    changeLocale(locale) {
      if (locale && locale !== this.$i18n.locale) {
        this.$i18n.locale = locale
        const to = this.$router.resolve({ params: { locale } })
        this.$router.push(to.location)
      }
    }
  },
  async mounted() {
    this.$i18n.locale = this.$route.params.locale
  },
  updated() {
    this.$i18n.locale = this.$route.params.locale
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/base/_variables';
.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $borderColor;
  height: 100%;
  padding: 64px;
  @media only screen and (max-width: 960px) {
    padding: 0px;
    background-color: $white;
  }
  .verification-card {
    max-width: 1312px;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    @media only screen and (min-width: 960px) {
      flex-direction: row;
      padding: 80px 0px;
      align-items: flex-start;
    }
    @media only screen and (max-width:960px) {
      justify-content: flex-start;
    }
    .step {
      padding: 4px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: unset;
      color: unset;
      .step-number {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid black;
        opacity: 0.4;
        margin-right: 12px;
        font-size: 1rem;
      }
      .step-label {
        opacity: 0.4;
      }
      &.active {
        .step-number {
          opacity: 1;
          border: none;
          background-color: $primaryColor;
        }
        .step-label {
          opacity: 1;
        }
      }
    }

    &-left {
      font-family: $fontFamily;
      width: 20%;
      padding-top: 45px;
      @media only screen and (max-width: 960px) {
        display: none;
      }
      .vertical-line-dotted {
        background-image: linear-gradient(
          rgba(0, 0, 0, 0.3) 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 2px 6px;
        background-repeat: repeat-y;
        height: 42px;
        margin-left: 16px;
        &.active {
          background-image: linear-gradient(
            rgba(253, 242, 147, 1) 33%,
            rgba(253, 242, 147, 0) 0%
          );
        }
      }
    }
    &-right {
      padding: 20px;
      width: 100%;
      @media only screen and (min-width: 960px) {
        width: 40%;
        padding: 0px 20px;
      }
      .options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .current-step {
          font-family: $fontFamily;
          font-weight: 500;
          opacity: 0.4;
          font-size: 1rem;
          padding-bottom: 10px;
        }
        button {
          font-family: $fontHeading;
          font-weight: 600;
          font-size: 1.125rem;
          height: auto;
          min-width: auto;
          padding: 0px;
          margin-left: 12px;
          border-bottom: 1px solid transparent;
          opacity: 0.6;
          &.active {
            opacity: 1;
            border-bottom: 1px solid $primaryColor;
          }
        }
      }

      .steps {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;
        @media only screen and (min-width: 960px) {
          display: none;
        }
        .step-number {
          margin: 0px;
        }
        .horizontal-line-dotted {
          margin: 0px 2px;
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 60%,
            rgba(0, 0, 0, 0.2) 30%
          );
          background-position: left;
          background-size: 5px 2px;
          background-repeat: repeat-x;
          width: 51px;
          &.active {
            background-image: linear-gradient(
              to right,
              rgba(255, 242, 147, 0) 60%,
              rgba(253, 242, 147, 1) 30%
            );
          }
        }
      }
      .subtitle {
        font-size: 1.125rem;
        font-family: $fontFamily;
        font-weight: normal;
        margin: 12px 0px;
      }
      .subtitle-list {
        list-style-type: disc;
      }
    }
  }
}
</style>
