import status from '../../contants/status'
import qs from 'qs'
import moment from 'moment'

const customer = {
  state: () => ({
    list: []
  }),
  mutations: {
    set_customer_list(state, data) {
      state.list = data
    }
  },
  actions: {
    deleteCustomer({ commit }, params) {
      return new Promise((resolve, reject) => {
        this.$http.delete('/customer/' + params.id).then((d) => {
          resolve(d)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getCustomerList({ commit }, params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/customer/paginate', {
          params,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }).then((resp) => {
          const { data } = resp.data
          data.docs = data.docs?.map((d) => {
            return {
              id: d._id,
              email: d.crms_meta.emails?.length ? d.crms_meta.emails[0] : '',
              name: d.name,
              dob: d.dob,
              country: d.verification.address.country,
              status: status[d.verification.status],
              verification_images: d.verification.images,
              student_verification_images: d.verification.studentImages,
              verified_by: d.verification.verifiedBy ? d.verification.verifiedBy : '',
              verified_at: d.verification.verifiedAt ? moment(d.verification.verifiedAt).format('DD MMM YYYY HH:mm') : '',
              rejected_by: d.verification.rejectedBy ? d.verification.rejectedBy : '',
              rejected_at: d.verification.rejectedAt ? moment(d.verification.rejectedAt).format('DD MMM YYYY HH:mm') : '',
              submitted_at: moment(d.verification.submitted_at ?? d.createdAt).format('DD MMM YYYY HH:mm')
            }
          })
          commit('set_customer_list', data)
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
      })
    }
  },
  getters: {
  }
}
export default customer
