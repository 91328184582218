<template>
  <div class="app-datepicker">
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <label class="app-datepicker-label">{{ label }}</label>
        <v-text-field
          :value="formattedDate"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :min="min"
        :max="max"
        @input="open = false"
        no-title
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DatePicker',
  data() {
    return {
      open: false
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    }
  },
  computed: {
    formattedDate() {
      return this.date ? moment(this.date).format('DD.MM.YYYY') : ''
    },
    date: {
      get: function () {
        return this.$attrs.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';
.app-datepicker {
  font-family: $fontFamily;
  &-label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  .v-text-field {
    padding-top: 0px;
    .v-input__slot {
      border-radius: 4px;
      border: 1px solid $borderColor;
      padding: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-bottom: 32px;
      &::before,
      &::after {
        content: none;
      }
      .v-text-field__slot {
        &::after {
          content: '';
          background: url('../../assets/images/calendar.svg');
          width: 26px;
          height: 24px;
          margin-top: 4px;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
}
.v-menu__content {
  box-shadow: 0 4px 12px 0 rgba(81, 64, 4, 0.08) !important;
  .v-picker--date {
    font-family: $fontFamily !important;
    .v-date-picker-header {
      &__value {
        font-size: 15.4px;
        flex: unset;
        font-family: $fontFamily;
        font-weight: 600;
        color: $headerSemiBoldColor !important;
        button:hover {
          color: $headerSemiBoldColor !important;
        }
      }
      .v-btn {
        color: $headerSemiBoldColor;
      }
      .v-btn:first-child {
        position: absolute;
        right: 50px;
      }
    }
    .v-date-picker-table {
      padding: 0px 12px 12px;
      height: auto;
      thead {
        th {
          font-size: 15.4px;
          font-weight: 600 !important;
          color: $headerSemiBoldColor !important;
        }
      }
      .v-btn__content {
        font-size: 15.4px;
        color: $headerSemiBoldColor !important;
        font-weight: normal !important;
      }
      .v-btn--disabled {
        .v-btn__content {
          color: rgba(0, 0, 0, 0.26) !important;
        }
      }
      &__current.accent--text {
        color: $primaryColor !important;
        border-radius: 4px;
      }
      .v-btn--active.accent {
        background-color: $primaryColor !important;
        border-radius: 4px;
        .v-btn__content {
          color: $headerSemiBoldColor !important;
        }
      }
      .v-btn {
        &:hover,
        &:focus {
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
