<template>
  <!-- <v-checkbox
    class="app-checkbox"
    v-model="inputVal"
    :label="label"
    color="yellow"
    @change="onChange"
  /> -->
  <div class="app-checkbox" :class="{disabled}">
    <label>
      <input
        type="checkbox"
        class="input"
        v-model="inputVal"
        @change="onChange"
        :disabled="disabled"
      />
      <span class="custom" :class="{ checked: inputVal, disabled }">
        <v-icon :color="color" size="16">
          mdi-check
        </v-icon>
      </span>
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  data() {
    return {
      checkedProxy: false
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    val: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    color() {
      if (this.disabled) {
        return 'transparent'
      } else if (this.inputVal) {
        return 'black'
      }

      return 'white'
    },
    inputVal: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        this.checkedProxy = newVal
      }
    }
  },
  methods: {
    onChange: function (e) {
      this.$emit('input', this.checkedProxy)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';
.app-checkbox {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  font-family: $fontFamily;
  font-size: 1rem;
  margin-bottom: 18px;
  .input {
    display: none;
  }

  &.disabled {
    label {
      color: rgba(0,0,0,0.25)
    }
  }

  .custom {
    width: 20px;
    height: 20px;
    border: 2px solid $borderColor;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    &.checked {
      background-color: $primaryColor;
      border: none;
    }
    // &.disabled {
    //   background-color: rgba(0, 0, 0, 0.1) !important;
    // }
  }
}
</style>
