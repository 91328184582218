<template>
  <div class="drop-image">
    <form ref="fileform" class="drop-image-form">
      <input
        ref="drop-image-input"
        class="drop-image-input"
        type="file"
        @change="onChoosen"
        accept="image/png,image/jpg,image/jpeg"
        multiple
        maxlength="3"
      />
      <img
        class="drop-files-image"
        src="../../assets/images/icons-upload.svg"
      />
      <span class="drop-files-text"
        >{{ $t('verification.drag-and-drop-pictures-here') }} <br />{{
          $t('verification.or')
        }}
      </span>
      <v-btn @click="chooseFiles" class="button browse-button">{{
        $t('verification.browse-files')
      }}</v-btn>
    </form>

    <div v-if="!isStudent">
      <h2 class="subtitle">
        {{ $t('verification.send-us-the-following-photos') }}
      </h2>
      <ul class="subtitle-list">
        <li>{{ $t('verification.photo-front-id') }}</li>
        <li>{{ $t('verification.photo-backside-id') }}</li>
        <li>{{ $t('verification.selfie-with-id-card-next-to-face') }}</li>
      </ul>
    </div>

    <div v-else>
      <h2 class="subtitle">
        {{ $t('verification.upload-the-following-pictures') }}
      </h2>
      <ul class="subtitle-list">
        <li>{{ $t('verification.picture-of-your-student-card-front') }}</li>
        <li>{{ $t('verification.picture-of-your-student-card-back') }}</li>
      </ul>
    </div>

    <div class="cropped">
      <template v-for="(item, index) in localImages">
        <div class="cropped-wrapper" :key="index">
          <img :src="item" class="cropped-image" />
          <v-btn
            class="cropped-remove"
            @click="removeCropped(index)"
            fab
            dark
            small
            color="red"
          >
            <v-icon color="white" size="16">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
      <div
        class="placeholder"
        v-for="item in (isStudent ? 2 : 3) - localImages.length"
        :key="'placeholder' + item"
        @click="chooseFiles"
      >
        {{ $t('verification.photo') }} {{ localImages.length + item }}
      </div>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="647px" persistent>
      <v-card class="crop-card">
        <v-card-title class="crop-card-title">{{
          $t('verification.edit-the-image')
        }}</v-card-title>
        <span class="crop-card-desc">{{
          $t('verification.hide-national-register-number')
        }}</span>

        <Loading :loading="loading" :absolute="true" :height="10" />
        <v-tabs
          v-show="files.length > 1"
          v-model="tab"
          background-color="transparent"
          show-arrows
        >
          <v-tab
            :disabled="Boolean(editMode)"
            v-for="(file, index) in files"
            :key="index"
            class="crop-card-tab"
          >
            {{ $t('verification.picture') }} {{ index + 1 }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="crop-card-tab-items">
          <v-tab-item v-for="(file, index) in files" :key="index" :eager="true">
            <canvas
              width="1080"
              height="720"
              :style="{ display: editMode !== 'crop' ? 'block' : 'none' }"
              ref="canvas"
              :class="`canvas ${editMode === 'brush' ? 'brush' : ''}`"
            ></canvas>
            <template v-if="editMode === 'crop'">
              <vue-cropper
                :containerStyle="{
                  width: '100%',
                  maxHeight: '330px'
                }"
                :imgStyle="{ maxHeight: '330px', width: '100%' }"
                :class="'cropper ' + index"
                ref="cropper"
                :src="file"
              ></vue-cropper>
            </template>
            <v-btn
              v-if="editMode"
              class="back-button"
              icon
              @click="cancelEdit(index)"
            >
              <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <div class="edit-button-group">
              <v-btn
                v-if="Boolean(editMode)"
                class="edit-button"
                icon
                @click="editDone(index)"
              >
                <v-icon dark> mdi-content-save </v-icon>
              </v-btn>
              <v-btn
                v-if="editMode === 'crop'"
                class="edit-button"
                icon
                @click="$refs['cropper'][index].rotate(90)"
              >
                <v-icon dark> mdi-rotate-right </v-icon></v-btn
              >
              <v-btn
                v-if="!Boolean(editMode)"
                class="edit-button"
                icon
                @click="brushMode(index)"
              >
                <v-icon dark> mdi-brush </v-icon>
              </v-btn>
              <v-btn
                v-if="!Boolean(editMode)"
                class="edit-button"
                icon
                @click="cropMode(index)"
              >
                <v-icon dark> mdi-crop </v-icon>
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <div class="crop-card-tab-actions">
          <div class="button-wrapper">
            <v-btn class="button inverted" @click="cancel">{{
              $t('verification.cancel')
            }}</v-btn>
          </div>
          <div class="button-wrapper">
            <v-btn
              :disabled="Boolean(editMode)"
              class="button"
              @click="saveChanges"
              v-if="tab == files.length - 1"
              >{{ $t('verification.save-changes') }}
            </v-btn>
            <v-btn
              :disabled="Boolean(editMode)"
              class="button"
              @click="nextTab"
              v-else
              >{{ $t('verification.next') }}</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import Loading from '@/components/Loading'
import heic2any from 'heic2any'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'DropImage',
  components: { VueCropper, Loading },
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
      editMode: false,
      dialog: false,
      tab: null,
      loading: true,
      drawing: false,
      fileIndex: 0
    }
  },
  props: {
    isStudent: Boolean
  },
  computed: {
    localImages: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable()
    if (this.dragAndDropCapable) {
      ;[
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop'
      ].forEach((evt) => {
        this.$refs.fileform.addEventListener(
          evt,
          (e) => {
            e.preventDefault()
            e.stopPropagation()
          },
          false
        )
      })
      this.$refs.fileform.addEventListener('dragend', function (e) {})
      this.$refs.fileform.addEventListener('dragleave', function (e) {})
      this.$refs.fileform.addEventListener('drop', (e) => {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files[i]
          if (file.type.indexOf('image/') === -1) {
            return
          }
          if (typeof FileReader === 'function') {
            this.convertImageToDataUrl(file, i)
          } else {
            console.log('Sorry, FileReader API not supported')
          }
        }
        if (this.files && e.dataTransfer.files.length > 0) {
          this.dialog = true
        }
      })
    }
  },
  methods: {
    nextTab() {
      this.tab += 1
    },
    chooseFiles() {
      this.$refs['drop-image-input'].click()
    },
    removeCropped(index) {
      this.localImages.splice(index, 1)
    },
    onChoosen(e) {
      if (e.target.files.length > (this.isStudent ? 2 : 3)) return
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        if (file.type.indexOf('image/') === -1) {
          return
        }
        if (typeof FileReader === 'function') {
          this.convertImageToDataUrl(file, i)
        } else {
          console.log('Sorry, FileReader API not supported')
        }
      }
      if (this.files && e.target.files.length > 0) {
        this.dialog = true
      }
    },
    convertImageToDataUrl(file, index) {
      this.loading = true
      heic2any({
        blob: file,
        toType: 'image/png',
        quality: 0.5
      })
        .then((blob) => {
          const reader = new FileReader()

          reader.onload = (event) => {
            this.loading = false
            this.files.push(event.target.result)
            setTimeout(() => this.drawCanvas(event.target.result, index), 500)
          }

          reader.readAsDataURL(blob)
        })
        .catch((e) => {
          this.loading = false
          if (e.code === 1) {
            const reader = new FileReader()

            reader.onload = (event) => {
              this.files.push(event.target.result)
              setTimeout(() => this.drawCanvas(event.target.result, index), 500)
            }

            reader.readAsDataURL(file)
            return
          }

          throw e
        })
    },
    determineDragAndDropCapable() {
      var div = document.createElement('div')
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },
    saveChanges() {
      if (this.files.length === 1) {
        if (this.localImages.length >= (this.isStudent ? 2 : 3)) return
        this.localImages.push(this.files[0])
      } else if (this.files.length > 1) {
        this.localImages = this.files
      }
      this.dialog = false
      this.files = []
      this.$refs['drop-image-input'].type = 'text'
      this.$refs['drop-image-input'].type = 'file'
    },
    cancel() {
      this.dialog = false
      this.editMode = false
      this.files = []
      this.$refs['drop-image-input'].type = 'text'
      this.$refs['drop-image-input'].type = 'file'
    },
    drawCanvas(data, index) {
      const canvas = this.$refs.canvas[index]
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      var image = new Image()
      image.onloadstart = (ev) => {
        this.loading = true
      }
      image.onerror = (ev) => {
        console.log(ev)
      }
      image.onload = (ev) => {
        const hRatio = canvas.width / image.width
        const vRatio = canvas.height / image.height
        const ratio = Math.min(hRatio, vRatio)
        const centerShiftX = (canvas.width - image.width * ratio) / 2
        const centerShiftY = (canvas.height - image.height * ratio) / 2
        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          centerShiftX,
          centerShiftY,
          image.width * ratio,
          image.height * ratio
        )
        this.loading = false
      }
      image.src = data
    },
    cropMode(index) {
      this.editMode = 'crop'
    },
    cancelEdit(index) {
      this.canvasRemoveListener(index)
      this.drawCanvas(this.files[index], index)
      this.editMode = false
    },
    brushMode(index) {
      this.editMode = 'brush'
      const canvas = this.$refs.canvas[index]
      const ctx = canvas.getContext('2d')
      const bounding = canvas.getBoundingClientRect()
      const cssScale = {
        x: canvas.width / canvas.offsetWidth,
        y: canvas.height / canvas.offsetHeight
      }
      this.canvasRemoveListener(index)
      canvas.addEventListener('mousedown', this.canvasMouseDown)
      canvas.addEventListener('mouseup', this.canvasMouseUp)
      canvas.addEventListener('mousemove', (e) =>
        this.canvasMouseMove(e, ctx, bounding, cssScale)
      )
      canvas.addEventListener('mouseout', this.canvasMouseOut)

      canvas.addEventListener('touchstart', this.canvasMouseDown)
      canvas.addEventListener('touchend', this.canvasMouseUp)
      canvas.addEventListener('touchmove', (e) =>
        this.canvasMouseMove(e, ctx, bounding, cssScale)
      )
      canvas.addEventListener('touchout', this.canvasMouseOut)
    },
    editDone(index) {
      if (this.editMode === 'brush') {
        const data = this.$refs.canvas[index].toDataURL()
        this.files[index] = data
        this.drawCanvas(data, index)
      } else if (this.editMode === 'crop') {
        const data = this.$refs.cropper[index]
          .getCroppedCanvas({ maxWidth: 1080, maxHeight: 1080 })
          .toDataURL('image/jpeg')
        this.files[index] = data
        this.drawCanvas(data, index)
      }
      this.editMode = false
    },
    canvasRemoveListener(index) {
      const canvas = this.$refs.canvas[index]
      const ctx = canvas.getContext('2d')
      const bounding = canvas.getBoundingClientRect()
      const cssScale = {
        x: canvas.width / canvas.offsetWidth,
        y: canvas.height / canvas.offsetHeight
      }
      canvas.removeEventListener('mousedown', this.canvasMouseDown)
      canvas.removeEventListener('mouseup', this.canvasMouseUp)
      canvas.removeEventListener('mousemove', (e) =>
        this.canvasMouseMove(e, ctx, bounding, cssScale)
      )
      canvas.removeEventListener('mouseout', this.canvasMouseOut)

      canvas.removeEventListener('touchstart', this.canvasMouseDown)
      canvas.removeEventListener('touchend', this.canvasMouseUp)
      canvas.removeEventListener('touchmove', (e) =>
        this.canvasMouseMove(e, ctx, bounding, cssScale)
      )
      canvas.removeEventListener('touchout', this.canvasMouseOut)
    },
    canvasMouseDown(e) {
      e.stopPropagation()
      e.preventDefault()
      this.drawing = true
    },
    canvasMouseMove(e, ctx, bounding, cssScale) {
      if (!this.drawing) return false
      let x = 0
      let y = 0
      if (e.touches && e.touches[0]) {
        x = (e.touches[0].clientX - bounding.left) * cssScale.x
        y = (e.touches[0].clientY - bounding.top) * cssScale.y
      } else {
        x = (e.clientX - bounding.left) * cssScale.x
        y = (e.clientY - bounding.top) * cssScale.y
        // x = e.clientX + e.layerX - bounding.left
        // y = e.clientY + e.layerY - bounding.top
      }
      const mouseX = parseInt(x)
      const mouseY = parseInt(y)
      ctx.beginPath()
      // ctx.fillStyle = '#FFFFFF'
      ctx.arc(mouseX, mouseY, 18, 0, Math.PI * 2)
      ctx.closePath()
      ctx.fill()
    },
    canvasMouseOut(e) {
      e.stopPropagation()
      e.preventDefault()
      this.drawing = false
    },
    canvasMouseUp(e) {
      e.stopPropagation()
      e.preventDefault()
      this.drawing = false
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.drop-image {
  .drop-image-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 38px;
    border: 2px dotted rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.3;
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        rgba(253, 221, 147, 0.4),
        rgba(253, 221, 147, 0)
      );
    }
    .drop-image-input {
      display: none;
    }
    .drop-files-text {
      padding: 12px 0px;
      font-family: $fontFamily;
      text-align: center;
    }
    .browse-button {
      width: auto;
    }
  }
  .cropped {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;
    &-wrapper {
      margin-right: 9px;
      @media only screen and (max-width: 600px) {
        margin-right: 4px;
      }
      margin-top: 24px;
      display: flex;
    }
    .placeholder {
      width: 128px;
      height: 128px;
      margin-top: 24px;
      margin-right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 600px) {
        margin-right: 10px;
        width: 88px;
        height: 88px;
      }
      background: $borderColor;
      border-radius: 10px;
    }
    &-image {
      width: 128px;
      height: 128px;
      @media only screen and (max-width: 600px) {
        width: 88px;
        height: 88px;
      }
      object-fit: contain;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    &-remove {
      margin-top: 2px;
      margin-left: 2px;
      width: 20px !important;
      height: 20px !important;
      @media only screen and (max-width: 600px) {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}

.crop-card {
  padding: 40px 48px;
  @media only screen and (max-width: 960px) {
    padding: 12px;
  }
  &-desc {
    font-size: 16px;
    font-weight: normal;
    opacity: 0.6;
    text-align: center;
    padding: 0px 16px 8px 16px;
  }
  .v-tabs {
    padding: 0px 8px;
    &-slider-wrapper {
      color: $primaryColor;
    }
  }
  &-tab {
    font-family: $fontHeading;
    color: black !important;
    &.v-tab--active {
      font-weight: 600;
    }
  }
  &-tab-items {
    margin: 24px 8px;
    margin-top: 12px;
    border-radius: 4px;
    height: 100% !important;
    .edit-button-group {
      top: 7px;
      right: 5px;
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .edit-button {
      background-color: $primaryColor;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }
    .back-button {
      position: absolute;
      left: 5px;
      top: 7px;
      background-color: $primaryColor;
    }
    .cropper,
    .canvas {
      width: 100%;
      &.brush {
        cursor: pointer;
      }
    }
    .cropper-container {
      width: 100%;
    }
  }
  &-title {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0px !important;
    font-family: $fontHeading;
    font-size: 1.5rem;
    font-weight: 600 !important;
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 24px;
  }
  &-tab-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0px !important;
    @media screen and (max-width:600px) {
      display: flex;
      flex-direction: column;
    }
    .button-wrapper {
      flex-grow: 1;
      margin: 8px;
    }
  }
}
</style>
