<template>
  <div class="text-field">
    <label class="text-field-label">{{ label }}</label>
    <v-text-field
      solo
      v-model="inputVal"
      :rules="rules"
      :type="type"
      :flat="true"
      :disabled="disabled"
      :error="error"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: Array,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    disabled: {
      get() {
        return this.$attrs.disabled
      }
    },
    inputVal: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';

.text-field {
  font-family: $fontFamily;
  &-label {
    color: $gray;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }

  .v-text-field {
    .v-input__slot {
      border: 1px solid $borderColor;
      margin-bottom: 18px;
    }
    &.v-input--is-disabled {
      .v-input__slot {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }
    }
    &.v-input--is-focused {
      .v-input__slot {
        border: 1px solid $primaryColor !important;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }

  .error--text {
    &.v-text-field {
      .v-input__slot {
        border: 1px solid $rejectedColor;
      }
    }
  }
}
</style>
