<template>
  <div class="about-you" v-if="!loading">
    <h1>{{ $t('verification.welcome') }}</h1>
    <span v-html="$t('verification.please-complete-procedure')"></span>
    <div class="form">
      <TextField
        v-model="name"
        :rules="rules.name"
        :label="$t('verification.name')"
        :disabled="true"
      ></TextField>

      <DatePicker
        :label="$t('verification.dob', 'Date of birth')"
        v-model="dob"
        :max="new Date().toISOString()"
      />

      <div class="resident">
        {{ $t('verification.residence') }}
      </div>
      <div class="d-flex flex-row justify-space-between">
        <TextField
          v-model="street"
          :rules="rules.street"
          :label="$t('verification.street')"
          class="address"
        ></TextField>
        <TextField
          v-model="number"
          :rules="rules.number"
          :label="$t('verification.number')"
          class="address"
          :error="numberValidation"
        ></TextField>
        <TextField
          v-model="additional_number"
          :label="$t('verification.additional-number')"
          class="address"
        ></TextField>
      </div>
      <div class="d-flex flex-row justify-space-between">
        <TextField
          v-model="city"
          :rules="rules.city"
          :label="$t('verification.city')"
          class="inline"
        ></TextField>
        <TextField
          v-model="postcode"
          :rules="rules.postcode"
          :label="$t('verification.postcode')"
          class="inline"
          :error="postValidation"
        ></TextField>
      </div>
      <TextField
        v-model="country"
        :rules="rules.country"
        :label="$t('verification.country')"
      />
      <CheckBox
        v-model="is_student"
        :label="$t('verification.i-am-a-student')"
        :disabled="disableStudent"
      />

      <h1>{{ $t('verification.questionnaire') }}</h1>

      <TextArea
        v-model="question_1"
        :rules="rules.question_1"
        :label="$t('verification.question_1')"
      />

      <TextArea
        v-model="question_2"
        :rules="rules.question_2"
        :label="$t('verification.question_2')"
      />

      <TextArea
        v-model="question_3"
        :rules="rules.question_3"
        :label="$t('verification.question_3')"
      />

      <!-- Error messages -->
      <v-alert
        dense
        border="left"
        color="red lighten-2"
        dark
        type="error"
        :value="numericError"
        transition="scale-transition"
      >
        {{ $t('verification.please-enter-numeric') }}
      </v-alert>

      <v-btn :disabled="!formIsValid" v-on:click="next" class="button">{{
        $t('verification.next-step')
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapFields } from '@/helpers/store'
import { TextField, CheckBox, DatePicker, TextArea } from '@/components'
import moment from 'moment'
export default {
  name: 'step-1',
  components: { TextField, CheckBox, DatePicker, TextArea },
  computed: {
    loading() {
      return this.$store.state.verification.loading
    },
    formIsValid() {
      return (
        this.name &&
        this.street &&
        this.number &&
        this.postcode &&
        this.country &&
        this.dob &&
        this.question_1 &&
        this.question_2 &&
        this.question_3
      )
    },
    numberValidation() {
      if (!this.numericError) return false
      else return isNaN(this.number)
    },
    postValidation() {
      if (!this.numericError) return false
      else return isNaN(this.postcode)
    },
    disableStudent() {
      if (!this.dob) return true
      if (moment(this.dob, 'YYYY-MM-DD').diff(moment(), 'years', true) < -26) return true
      // if ()
      return false
    },
    minDate() {
      return moment().subtract(26, 'years').toISOString()
    },
    // mapping store state to this vue
    ...mapFields({
      fields: [
        'email',
        'name',
        'street',
        'number',
        'additional_number',
        'postcode',
        'country',
        'is_student',
        'dob',
        'city',
        'question_1',
        'question_2',
        'question_3'
      ],
      base: 'verification',
      mutations: 'update_verification_form'
    })
  },
  data() {
    return {
      rules: {
        name: [(val) => (val || '').length > 0 || 'This field is required'],
        street: [(val) => (val || '').length > 0 || 'This field is required'],
        number: [(val) => (val || '').length > 0 || 'This field is required'],
        postcode: [(val) => (val || '').length > 0 || 'This field is required'],
        city: [(val) => (val || '').length > 0 || 'This field is required'],
        country: [(val) => (val || '').length > 0 || 'This field is required'],
        dob: [(val) => (val || '').length > 0 || 'This field is required'],
        question_1: [(val) => (val || '').length > 0 || 'This field is required'],
        question_2: [(val) => (val || '').length > 0 || 'This field is required'],
        question_3: [(val) => (val || '').length > 0 || 'This field is required']
      },
      numericError: false
    }
  },
  async mounted() {
    this.$store.commit('update_verification_form', {
      field: 'id',
      value: this.$route.params.id
    })
    this.$store.commit('update_verification_form', {
      field: 'loading',
      value: true
    })
    try {
      await this.$store.dispatch('getUserById', {
        id: this.$route.params.id
      })
      this.$store.commit('update_verification_form', {
        field: 'loading',
        value: false
      })
    } catch (error) {
      this.$store.commit('update_verification_form', {
        field: 'loading',
        value: false
      })
      console.log(error)
    }
  },
  methods: {
    next() {
      if (isNaN(this.number) || isNaN(this.postcode)) {
        this.numericError = true
      } else {
        if (this.disableStudent) {
          this.is_student = false
        }

        this.$router.push('step-2')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables';
.about-you {
  .form {
    padding-top: 20px;
    .resident {
      margin-bottom: 18px;
    }
    .inline {
      &:first-child {
        width: 70%;
      }
      width: 25%;
    }
    .address {
      &:first-child {
        width: 40%;
      }
      width: 25%;
    }
  }
}
</style>
