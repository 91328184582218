<template>
  <div class="customer">
    <Loading :loading="loading" :absolute="true" :height="10" />
    <h1>Klanten</h1>
    <div class="customer-table-wrapper">
      <div class="search">
        <TextField
          class="search-input"
          label="Search name, email, or address"
          v-model="search"
          @input="getList"
        />
        <v-icon class="search-icon" size="30">mdi-magnify</v-icon>
      </div>
      <Table
        :options.sync="options"
        :headers="headers"
        :items-per-page="10"
        :items="customerList.docs"
        :serverItemsLength="customerList.totalDocs"
        class="customer-table"
        :handleClick="handleClick"
        :handleOnStatusFilter="handleOnStatusFilter"
        :handleOnDelete="handleOnDelete"
      />
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ alert_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="yellow" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { Table, Loading, TextField } from '@/components'
import status from '../../../contants/status'
export default {
  name: 'customer',
  components: {
    Table,
    Loading,
    TextField
  },
  computed: {
    customerList() {
      return this.$store.state.customer.list ?? []
    }
  },
  methods: {
    async handleOnDelete(id) {
      this.loading = true
      const { data } = await this.$store.dispatch('deleteCustomer', { id })
      if (data?.code === 200) {
        this.snackbar = true
        this.alert_text = 'Data has been deleted.'
        this.getList()
      } else {
        this.snackbar = true
        this.alert_text = 'Could not delete data, please try again later.'
      }
      this.loading = false
    },
    handleClick(e) {
      this.$router.push({
        name: 'customer-detail',
        params: { id: e.id }
      })
    },
    handleOnStatusFilter(stat) {
      const currentSelected = this.headers.find((d) => d.value === 'status')
        .selected
      const selected = currentSelected === stat ? false : stat
      const headers = this.headers.find((d) => d.value === 'status')
      if (headers && typeof headers.selected !== 'undefined') {
        this.filterStatus = selected
        headers.selected = selected
      }
      setTimeout(() => this.getList(), 200)
    },
    async getList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      this.loading = true
      const params = {
        offset: itemsPerPage > -1 ? itemsPerPage * (page - 1) : 0,
        limit: itemsPerPage > -1 ? itemsPerPage : this.customerList.totalDocs,
        q: this.search ?? '',
        sort: {}
      }
      if (this.filterStatus) {
        params.status = status[this.filterStatus]
      }

      const sortFields = Object.assign([], sortBy)
      const sortDescending = Object.assign([], sortDesc)

      if (sortFields.length !== 0) {
        for (let i = 0; i < sortFields.length; i++) {
          params.sort[sortFields[i]] = sortDescending[i] ? -1 : 1
        }
      }

      await this.$store.dispatch('getCustomerList', params)
      this.loading = false
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true
    }
  },
  data() {
    return {
      search: '',
      loading: false,
      options: {},
      snackbar: false,
      alert_text: '',
      headers: [
        {
          text: 'Email',
          align: 'start',
          value: 'email',
          sortable: false
        },
        { text: 'Land', value: 'country', sortable: false },
        { text: 'ID', value: 'verification_images', sortable: false },
        {
          text: 'Studentenkaart',
          value: 'student_verification_images',
          sortable: false
        },
        {
          text: 'Submitted At',
          value: 'submitted_at',
          sortable: false
        },
        {
          text: 'Verified by/at',
          value: 'verified_by',
          sortable: false
        },
        {
          text: 'Rejected by/at',
          value: 'rejected_by',
          sortable: false
        },
        {
          text: 'Toestand',
          value: 'status',
          options: ['Alle', 'Nieuw', 'Geverifieerd', 'Geweigerd', 'Expired'],
          selected: false,
          sortable: false
        },
        {
          text: '',
          value: 'id',
          sortable: false
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';

.customer {
  background: $borderColor;
  padding: 30px;
  min-height: calc(100vh - 75px);

  &-table-wrapper {
    margin-top: 15px;
  }
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-icon {
      padding: 10px;
    }
  }
}
</style>
