import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = {
  en: require('../locales/en.json'),
  nl: require('../locales/nl.json'),
  fr: require('../locales/fr.json'),
  de: require('../locales/de.json')
}
const i18n = new VueI18n({
  locale: 'nl',
  messages: messages
})
export default i18n
