import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/vuei18n'
import api from './api'
import Vuex from 'vuex'

Vue.config.productionTip = false

Vuex.Store.prototype.$http = api
Vue.prototype.$http = api

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      const { url } = error.response.config
      switch (error.response.status) {
        case 400:
          if (url.indexOf('/customer/unverified') > -1) {
            router.replace({
              path: '/404'
            })
          }

          if (url.indexOf('/auth/login') > -1) {
            return Promise.reject(error.response)
          }

          break
        case 401:
          if (url.indexOf('/customer/unverified') > -1) {
            const id = url.split('/')[3]
            router.replace({ path: '/nl/verification/' + id + '/all-set' })
          } else {
            router.replace({
              path: '/logout',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }
          break
        case 403:
          router.replace({
            path: '/logout',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 404:
          if (url.indexOf('/customer/unverified') > -1) {
            router.replace({
              path: '/404'
            })
          }
          break
        case 502:
          setTimeout(() => {
            router.replace({
              path: '/logout',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
          break
      }
      return Promise.reject(error.response.status)
    }
  }
)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
