<template>
  <div class="text-field">
    <label class="text-field-label">{{ label }}</label>
    <v-textarea
      solo
      :rules="rules"
      :type="type"
      :flat="true"
      :disabled="disabled"
      v-model="inputVal"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: Array,
      required: false
    }
  },
  computed: {
    disabled: {
      get() {
        return this.$attrs.disabled
      }
    },
    inputVal: {
      get() {
        return this.$attrs.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/_variables.scss';

.text-field {
  font-family: $fontFamily;
  &-label {
    color: $gray;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }

  .v-text-field {
    .v-input__slot {
      border: 1px solid $borderColor;
      margin-bottom: 18px;
    }
    &.v-input--is-focused {
      .v-input__slot {
        border: 1px solid $primaryColor !important;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
